<template>
    <div class="student-home">
      <!-- 반응형 헤더 이미지 -->
      <div class="header-image" :class="{ 'mobile-bg': isMobile, 'desktop-bg': !isMobile }">
        <img src="@/assets/images/home_header.jpg" alt="수강생 홈 헤더 이미지" />
      </div>
  
      <!-- 날씨 위젯 -->
      <div class="weather-block">
        <WeatherWidget :isUserMode="true" />
      </div>
  
      <!-- 교육원 전체 일정 -->
      <div class="calendar">
        <h3 class="indicator-title">교육원 전체 일정</h3>
        <div class="calendar-container">
          <full-calendar
            :plugins="calendarPlugins"
            :initial-view="'dayGridMonth'"
            :events="events"
            :header="{
              left: '',
              center: 'title',
              right: 'prev next'
            }"
            :displayEventTime="false"
            @eventClick="handleEventClick"
          />
         
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import WeatherWidget from "@/components/WeatherWidget.vue";
  import FullCalendar from '@fullcalendar/vue';
  // eslint-disable-next-line no-unused-vars
  import dayGridPlugin from '@fullcalendar/daygrid'; 
  import Swal from 'sweetalert2';

  
  
  export default {
    name: 'UserHome',
    components: {
       WeatherWidget,
      FullCalendar
    },
    data() {
      return {
        isMobile: false,
        windowWidth: 0,
        calendarPlugins: [dayGridPlugin], // FullCalendar 플러그인
        events: [] // 이벤트 데이터
      };
    },
    mounted() {
      this.checkViewport();
      window.addEventListener('resize', this.checkViewport);
      this.fetchEvents(); // 일정 데이터 불러오기
    },
    methods: {
      checkViewport() {
        this.isMobile = window.innerWidth <= 768;
        this.windowWidth = window.innerWidth;
      },
      handleEventClick(event) {
        const eventData = event.event.extendedProps;

      // ✅ 날짜 변환 (UTC → 로컬 시간)
      const startDate = event.event.start 
          ? new Date(event.event.start).toLocaleString("ko-KR", { 
              year: "numeric", month: "2-digit", day: "2-digit",
              hour: "2-digit", minute: "2-digit", second: "2-digit"
          }) 
          : "정보 없음";

      const endDate = event.event.end 
          ? new Date(event.event.end).toLocaleString("ko-KR", { 
              year: "numeric", month: "2-digit", day: "2-digit",
              hour: "2-digit", minute: "2-digit", second: "2-digit"
          }) 
          : "정보 없음";

          const attendeesList = eventData.attendees.length > 0 
              ? eventData.attendees.map(a => `${a.name}`).join("<br>")
              //? eventData.attendees.map(a => `${a.name} (${a.phone})`).join("<br>")
              : "참석자 없음";

              Swal.fire({
            icon: 'info',
            title: event.event.title,
            html: `
                <p><strong>📅 시작일시:</strong> ${startDate}</p>
                <p><strong>⏳ 종료일시:</strong> ${endDate}</p>
                <p><strong>📝 내용:</strong> ${eventData.content || "상세 내용 없음"}</p>
                <p><strong>👥 참석자:</strong> ${attendeesList}</p>
            `,
            confirmButtonText: '닫기'
        });
      },
      
      async fetchEvents() {
          const tokenData = JSON.parse(sessionStorage.getItem('token'));
          const token = tokenData ? tokenData.access_token : '';

          if (!token) {
            Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
            return;
          }

          try {
              // ✅ 일정 데이터 가져오기
              const calendarResponse = await axios.get('/calendar', { 
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                  },
              });

              const calendars = calendarResponse.data;

              // ✅ batch 리스트 추출 (중복 제거)
              const batchList = [...new Set(calendars.map(c => c.batch).filter(b => b))]; 

              // ✅ batch 기반 참석자 데이터 요청
              const attendeesByBatch = {};
              if (batchList.length > 0) {
                  const batchRequests = batchList.map(batch =>
                      axios.get(`/calendar/batch-attendees/${batch}`, {
                          headers: { Authorization: `Bearer ${token}` },
                      })
                  );

                  const batchResponses = await Promise.all(batchRequests);
                  batchResponses.forEach((res, idx) => {
                      attendeesByBatch[batchList[idx]] = res.data; // 해당 batch 참석자 저장
                  });
              }

              // ✅ FullCalendar 데이터 변환
              this.events = calendars.map(calendar => {
                  const calendarAttendees = attendeesByBatch[calendar.batch] || []; // 참석자 매칭

                  return {
                      id: calendar.uuid,
                      title: calendar.subject,
                      start: calendar.start_time,
                      end: calendar.end_time,
                      backgroundColor: calendar.backgroundColor || '#2396f2',
                      extendedProps: {
                          content: calendar.contents || "내용 없음", 
                          attendees: calendarAttendees, // 🟢 참석자 추가
                      },
                  };
              });

          } catch (error) {
              console.error("일정 데이터를 불러오는 중 오류 발생:", error);
              Swal.fire('오류', '일정 데이터를 불러오는 데 실패했습니다.', 'error');
          }
      },



      formatKoreanTime(isoTime) {
        if (!isoTime) return '';
        
        const date = new Date(isoTime);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const period = hours < 12 ? '오전' : '오후';

        if (hours === 0) {
          hours = 12;
        } else if (hours > 12) {
          hours -= 12;
        }

        return `${period} ${hours}시${minutes > 0 ? ` ${minutes}분` : ''}`; // ✅ 오전 12시 or 오후 3시 30분
      },
      goToDetailedCalendar(info) {
        // 클릭한 이벤트 상세 페이지로 이동
        this.$router.push(`/calendar/${info.event.id}`);
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkViewport);
    }
  }
  </script>
  
  <style scoped>
  /* 메인 컨테이너 */
  .student-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  /* 반응형 헤더 이미지 */
  .header-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* 날씨 위젯 */
  .weather-block {
    border: none;
    padding: 5px 0;
    background-color: white;
    /* width: 100%;
    max-width: 600px; */
    margin-top: 20px;
  }
  
  /* 교육원 전체 일정 */
  .calendar {
    margin: 20px auto;
    text-align: left;
    width: 90%;
    max-width: 1000px;
  }
  .indicator-title {
    font-size: 24px;
    text-align: center;
  }
  .calendar-container {
    max-width: 100%;
    margin: 0 auto;
    font-size: 1.2em;
  }
 

.weather-widget {
  text-align: center;
  font-size: 2vw; /* 전체 화면 크기에 맞춰 기본적으로 조정됨 */
}

.weather-widget h3 {
  font-size: 2.5vw; /* 전체 화면 크기에 맞춰 기본적으로 조정됨 */
}

/* 768px 이하에서는 폰트 크기 더 줄이기 */
@media (max-width: 768px) {
  .weather-widget {
    font-size: 1.5vw;
  }
  .weather-widget h3 {
    font-size: 2vw;
  }
  .indicator-title {
    font-size: 24px;
    text-align: center;
  }
}
/* 
/* 480px 이하에서는 더욱 줄이기 */
/*@media (max-width: 480px) {
  .weather-widget {
    font-size: 1vw;
  }
  .weather-widget h3 {
    font-size: 1.5vw;
  }
} */


  </style>
  